<template>
  <CCard>
    <CCardBody>
      <div class="mb-4">
        <!-- <small class="font-weight-bold text-uppercase text-dark">{{title}}</small> -->
        <h5 class="text-center text-dark text-uppercase">{{title}}</h5>
        <CButton v-if="enableBtnDel"
          class="m-0 p-0 px-1 ml-3 d-inline-block float-right"
        >
          <CIcon name="cil-x" />
        </CButton>
        <slot name="view_menu" />
      </div>
      <slot name="chart_description" />
      <slot name="chart" />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    title: {type: String, default: null},
    enableBtnDel: {type: Boolean, default: false}
  }
}
</script>