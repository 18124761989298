<template>
  <CardContainer :title="titleCompose" v-if="stats">
    <template slot="view_menu" v-if="false">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
    </template>
    <template slot="chart">
      <center>
        <div style="max-width: 630px;">
          <CChartLine
            :datasets="chartData"
            :labels="chartLabel"
            :options="chartOption"
          />
        </div>
      </center>
    </template>
  </CardContainer>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/StatisticsCard.vue'
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'WeekAmount',
  components: {
    CardContainer,
    CChartLine
  },
  data() {
    return {
      stats: []
    }
  },
  computed: {
    titleCompose() {
      return this.$t('cloud.title.recent_quantity_trend');
    },
    chartData() {
      // *** COLOR Palette
      //  #f3a935  (243,169,53)  warning
      //  #c73558  (199,53,88)   danger
      //  #6ebe9f  (110,190,159) success
      //  #2586a4  (37,134,164)  primary
      //  #55596a  (85,89,106)   secondary
      return [
        {
          label: this.$t('cloud.title.recent_quantity_quantity'),
          data: this.stats.map(el => el.total),
          borderColor: '#2586a4',
          fill: false,
          tension: 0.4
        }
      ]
    },
    chartLabel() {
      if (this.stats.length > 8) {
        return this.stats.map((el, index) => {
          if (index%3===0) return new Date(el.date).getDate();
          return '';
        });
      }
      return this.stats.map(el => {
        let t = new Date(el.date);
        return this.$t('cloud.title.month_day', {mon: (1+t.getMonth()), day: t.getDate()});
      });
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: '㎥'
            },
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }]
        }
      };
    }
  },
  mounted() {
    this.statDailyAmount();
  },
  methods: {
    statDailyAmount() {
      let query_string = '';
      const params = {};
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      params.ets = now.getTime();
      params.sts = params.ets - (1000 * 60 * 60 * 24)*7;
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/statistics/daily-fluctuations/${query_string}`)
        .then(result => {
          this.stats = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>
