<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="view_menu" v-if="false">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
    </template>
    <template slot="chart">
      <center>
        <div style="max-width: 630px;">
          <CChartPie
            :datasets="chartData"
            :labels="chartLabel"
            :options="chartOption"
          />
        </div>
      </center>
    </template>
  </CardContainer>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import CardContainer from '@/containers/StatisticsCard.vue'
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'CurrentOccupancy',
  components: {
    CardContainer,
    CChartPie
  },
  data() {
    return {
      stats: null,
      title: this.$t('cloud.title.card_current_occupancy'),
      colors: ['#c73558', '#6ebe9f', '#ccc'],
      labels: []
    }
  },
  computed: {
    // *** datasets configurations ***
    // Name	                    Type         Scriptable      Indexable      Default
    // -----------------------------------------------------------------------------------------
    // backgroundColor          Color        Yes             Yes            'rgba(0, 0, 0, 0.1)'
    // borderAlign              string       Yes             Yes            'center'
    // borderColor              Color        Yes             Yes            '#fff'
    // borderWidth              number       Yes             Yes            2
    // data                     number[]     -               -              required
    // hoverBackgroundColor     Color        Yes             Yes            undefined
    // hoverBorderColor         Color        Yes             Yes            undefined
    // hoverBorderWidth         number       Yes             Yes            undefined
    // weight                   number       -               -              1
    chartData() {
      let occupied = this.stats.occupied;
      let free = this.stats.free;
      let unknown = this.stats.unknown;
      return [
        {
          backgroundColor: this.colors,
          data: [occupied, free, unknown]
        }
      ];
    },
    chartLabel() {
      if (this.stats.capacity > 0) {
        let occupied = this.stats.occupied;
        let free = this.stats.free;
        let unknown = this.stats.unknown;
        return [
          this.$t('cloud.title.card_label_occupied', {occupied: occupied}),
          this.$t('cloud.title.card_label_free', {free: free}),
          this.$t('cloud.title.card_label_unknown', {unknown: unknown})
        ];
      }
      return ['No place']
    },
    chartOption() {
      return {
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            fontSize: 12
          }
        }
      };
    }
  },
  mounted() {
    this.statCurrentOccupancy();
  },
  methods: {
    statCurrentOccupancy() {
      let query_string = '';
      const params = {};
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      params.sts = now.getTime();
      params.ets = params.sts + (1000 * 60 * 60 * 24);
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/parking/statistics/current-occupancy/${query_string}`)
        .then(result => {
          this.stats = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>
