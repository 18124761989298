<template>
  <CRow>
    <CCol md="12" lg="4">
      <Table
        :items="rank_month"
        :fields="FIELDS"
        :caption="$t('cloud.title.monthly_visit_increase')"
        hover
        small
        fixed
        :view-btn="false"
      />
    </CCol>
    <CCol md="12" lg="4">
      <Table
        :items="rank_week"
        :fields="FIELDS"
        :caption="$t('cloud.title.weekly_visit_increase')"
        hover
        small
        fixed
        :view-btn="false"
      />
    </CCol>
    <CCol md="12" lg="4">
      <Table
        :items="rank_day"
        :fields="FIELDS2"
        :caption="$t('cloud.title.visit_increase')"
        hover
        small
        fixed
        :view-btn="false"
      />
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import Table from '@/components/EdxTable'

export default {
  name: 'DashboardCardStoreRank',
  components: {
    Table
  },
  data() {
    return {
      rank_month: [],
      rank_week: [],
      rank_day: [],

      FIELDS: [
        {key: 'name', label: this.$t('cloud.title.name'), _style: 'width: 40%;'},
        {key: 'total', label: this.$t('cloud.title.total'), _classes: 'align-middle'},
        {key: 'avg', label: this.$t('cloud.title.daily_avg'), _classes: 'align-middle'},
        {key: 'increase', label: this.$t('cloud.title.increase'), _classes: 'align-middle'},
      ],
      FIELDS2: [
        {key: 'name', label: this.$t('cloud.title.name'), _style: 'width: 40%;'},
        {key: 'total', label: this.$t('cloud.title.total'), _classes: 'align-middle'},
        {key: 'increase', label: this.$t('cloud.title.increase'), _classes: 'align-middle'},
      ],
    }
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.statCustomerVisitCounts();
    },
    statCustomerVisitCounts() {
      let query_string = '';
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      // 오늘 0시의 ts
      const ts_0 = date.getTime();
      const ts_30 = ts_0 - 30 * 24 * 60 * 60 * 1000;
      const ts_60 = ts_0 - 60 * 24 * 60 * 60 * 1000;
      const ts_7 = ts_0 - 7 * 24 * 60 * 60 * 1000;
      const ts_14 = ts_0 - 14 * 24 * 60 * 60 * 1000;
      const ts_now = Date.now();
      const ts_7_1 = ts_7 + (ts_now - ts_0);
      const params = {};

      params.ts1 = ts_60;
      params.ts2 = ts_30;
      params.ts3 = ts_30;
      params.ts4 = ts_0;
      query_string = '?' + qs.stringify(params);
      axios.get(`/api/bi/statistics/customer-visit-increase/${query_string}`)
        .then(result => {
          this.rank_month = result.data.map(el => {
            let increase = el.rate ? `${el.rate} %`:'-'
            return {
              name: el.name,
              total: el.period2_count,
              avg: Math.round(el.period2_count / 30),
              increase: increase
            }
          })
        })
        .catch(error => {
          console.error(error);
        })

      params.ts1 = ts_14;
      params.ts2 = ts_7;
      params.ts3 = ts_7;
      params.ts4 = ts_0;
      query_string = '?' + qs.stringify(params);
      axios.get(`/api/bi/statistics/customer-visit-increase/${query_string}`)
        .then(result => {
          this.rank_week = result.data.map(el => {
            let increase = el.rate ? `${el.rate} %`:'-'
            return {
              name: el.name,
              total: el.period2_count,
              avg: Math.round(el.period2_count / 7),
              increase: increase
            }
          })
        })
        .catch(error => {
          console.error(error);
        })

      params.ts1 = ts_7;
      params.ts2 = ts_7_1;
      params.ts3 = ts_0;
      params.ts4 = ts_now;
      query_string = '?' + qs.stringify(params);
      axios.get(`/api/bi/statistics/customer-visit-increase/${query_string}`)
        .then(result => {
          this.rank_day = result.data.map(el => {
            let increase = el.rate ? `${el.rate} %`:'-'
            return {
              name: el.name,
              total: el.period2_count,
              increase: increase
            }
          })
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>