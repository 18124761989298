<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="chart">
      <CChartLine
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import { hexToRgba } from '@coreui/utils/src'
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'CompCardSimpleLineChart',
  components: {
    CardContainer,
    CChartLine
  },
  props: {
    title: {type:String,default:'Data'},
    stats: {type:Array}, // [{value:숫자, x:시간}, ...] 
    labels: {type:Array},
    colors: {type:Array},
    bgColors: {type:Array},
    lineStyle: {type:Array},
    legend: {type:Boolean,default:true},
    x_unit: {type:String,default:''},
    y_unit: {type:String,default:''},
    aspect_ratio: {type:Number}
  },
  computed: {
    chartData() {
      // let delta = {
      //   label: 'Count',
      //   data: this.stats.map(el => el.delta),
      //   borderColor: '#f0c33c',
      //   fill: false,
      //   tension: 0.4
      // };
      // let inc = {
      //   label: 'Increase',
      //   data: this.stats.map(el => el.inc),
      //   borderColor: '#358ec4',
      //   fill: false,
      //   tension: 0.4
      // };
      // let dec = {
      //   label: 'Decrease',
      //   data: this.stats.map(el => el.dec),
      //   borderColor: '#e65054',
      //   fill: false,
      //   tension: 0.4
      // };
      // return [delta, inc, dec];
      const rtn = [];
      for (const i in this.stats) {
        let bgColor = null;
        if (this.bgColors && this.bgColors.length > i && this.bgColors[i] !== 'none') {
          bgColor = hexToRgba(this.bgColors[i], 20);
        }
        let lineStyle = null;
        if (this.lineStyle && this.lineStyle.length > i && this.lineStyle[i] !== 'line') {
          lineStyle = [8,5];
        }
        const record = {
          label: this.labels[i],
          data: this.stats[i].map(el => el.value),
          borderColor: this.colors[i],
          pointHoverBackgroundColor: this.colors[i],
          borderWidth: 1.8,
          tension: 0.3,
          fill: false
        };
        if (bgColor) {
          record.fill = true;
          record.backgroundColor = bgColor;
        }
        if (lineStyle) {
          record.borderDash = [4, 2];
        }
        rtn.push(record);
      }
      return rtn;
    },
    chartLabel() {
      let rtn = [];
      let stat = this.stats[0];
      if (stat.length === 24) {
        for (let i in stat) {
          const d = new Date(stat[i].x);
          const hour = d.getHours();
          // const ampm = this.$t('cloud.title.ampm_am');
          const ampm = '';
          // if (hour >= 12) ampm = this.$t('cloud.title.ampm_pm');
          if (i % 4 === 0) rtn.push(ampm+' '+hour);
          else rtn.push(hour);
        }
      } else if (stat.length === 7) {
        rtn = [
          this.$t('cloud.title.dow_sun'),
          this.$t('cloud.title.dow_mon'),
          this.$t('cloud.title.dow_tue'),
          this.$t('cloud.title.dow_wed'),
          this.$t('cloud.title.dow_thu'),
          this.$t('cloud.title.dow_fri'),
          this.$t('cloud.title.dow_sat')
        ];
      } else {
        for (let i in stat) {
          // if (i === 0 || i % 5 === 4) {
          //   rtn.push(+i+1);
          // } else {
          //   rtn.push('');
          // }
          const d = new Date(stat[i].x);
          const mon = d.getMonth() + 1;
          const date = d.getDate();
          rtn.push(`${mon}/${date}`);
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          display: this.legend,
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.x_unit
            },
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.y_unit
            },
            ticks: {
              beginAtZero: true,
              // max: 100
            },
            gridLines: {
              display: true
            }
          }]
        },
        aspectRatio: this.aspect_ratio,
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      };
    }
  }
}
</script>